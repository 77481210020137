<div *ngIf="isLoaded" class="card-container">
    <a class="header-back" [routerLink]="['/app']">
        <mat-icon inline=true fontSet="fa" fontIcon="fa-arrow-left" aria-label="back"></mat-icon>
        <span>My portfolios</span>
    </a> / {{portfolio.name }}

    <mat-toolbar>
        <h1>Backtest</h1>
    </mat-toolbar>
    <mat-card class="settings" id="settings">
        <mat-card-content>
            <section id="settings-section-optimized">
                <div class="card mb-4">
                    <div class="card-header">
                        <h1>Settings <app-info infoIndex="4"></app-info></h1>

                        <div class="d-inline-block ml-4 mt-4" *ngIf="portfolio">
                            <div class="row">
                                <div class="col divider-right">
                                    <app-cim-datepicker class="header-select" label="Start Date"
                                                        [minDate]="portfolio.start_date"
                                                        [model]="portfolio.start_date"
                                                        (modelChanged)="portfolio.start_date = $event;calculateDrawdown(plotData.cumulative_returns)"></app-cim-datepicker>
                                </div>
                                <div class="col">
                                    <app-cim-datepicker class="header-select" label="End Date"
                                                        [minDate]="chartGlobalStartDate ? chartGlobalStartDate : portfolio.start_date"
                                                        [model]="portfolio.end_date"
                                                        (modelChanged)="portfolio.end_date = $event;calculateDrawdown(plotData.cumulative_returns)"></app-cim-datepicker>
                                </div>
                                <div class="col">
                                    <app-benchmark-selector
                                            [selectedBenchmark]="selectedBenchmark"
                                            (benchmarkSelect)="onBenchmarkSelect($event)"></app-benchmark-selector>
                                </div>

                                <div class="col">

                                    <div class="ml-auto text-right">
<!--                                        <a *ngIf="portfolio" mat-flat-button color="primary" class="mr-3"-->
<!--                                           [routerLink]="['/app/portfolio-view/'+portfolio.id+'/overview/print']"-->
<!--                                           target="_blank"><span class="navigation-button">PDF</span></a>-->
                                        <button type="button" *ngIf="portfolio" mat-flat-button color="primary"
                                                class="mr-3"
                                                [routerLink]="['/app/portfolio', portfolio.id]"><span class="navigation-button">Edit Portfolio</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="col">
                                    <button class="fixed-optimize-btn" mat-flat-button color="primary"
                                            (click)="onCalculateReturnsClick()"><span class="navigation-button">Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </section>
        </mat-card-content>
    </mat-card>

    <mat-card id="description-card">
        <div class="row mb-3">
                    <div class="col-md-12">
                        <div class="card h-100">
                            <div class="card-header"><h1>Description</h1></div>
                            <div class="card-body">
                                <div class="overview-text mb-0" style="font-size: 14px" [innerHTML]="portfolio.description"></div>
                            </div>
                        </div>
                    </div>

                </div>
    <div class="col-md-12 flex">
        <div class="col-md-6">
            <mat-card>
                <div class="card h-100" style="height: 300px !important;">
                    <div class="card-header">Portfolio Characteristics <app-info infoIndex="5"></app-info></div>
                    <div class="card-body">
                        <div class="info-section">
                            <div class="info-line">
                                <span class="info-title">Start Date</span>
                                <span class="info-value">{{ portfolio.start_date }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">End Date</span>
                                <span class="info-value">{{ portfolio.end_date }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Number of Assets</span>
                                <span class="info-value">{{ portfolio.securities ?  portfolio.securities.length : "-"}}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Rebalancing</span>
                                <span class="info-value"
                                      style="text-transform: capitalize">{{ portfolio.rebalancing_type || 'No rebalancing' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Cost of Rebalancing</span>
                                <span class="info-value">{{ portfolio.cost_of_rebalancing }} bps</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Currency</span>
                                <span class="info-value">{{ portfolio.currency.code }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Weighting</span>
                                <span class="info-value"
                                      style="text-transform: capitalize">{{ portfolio.niceWeightType() }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Simulation Date</span>
                                <span class="info-value">{{ (portfolio.last_updated| date:'yyyy-MM-dd') || '-' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">FX Hedge</span>
                                <span class="info-value">

                            <ng-template ngFor let-hedg [ngForOf]="portfolio.currency_hedging"
                                         let-i="index">
                                    {{ hedg.currency_code }}({{ hedg.hedge_pct }}%)
                            </ng-template>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>
        <div class="col-md-6">
            <mat-card>
                <div class="card h-100" style="height: 300px !important;">
                    <div class="card-header">Valuation <app-info infoIndex="6"></app-info></div>
                    <div class="card-body">
                        <div class="info-section">
                            <div class="info-line">
                                <span class="info-title">Dividend Yield</span>
                                <span class="info-value">{{ portfolio.performance_summary['Dividend Yield'] ? (portfolio.performance_summary['Dividend Yield']['Portfolio']|number:'1.0-2') : '0' }}</span><span>%</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">P/E</span>
                                <span class="info-value" style="padding-right: 15px;">{{ portfolio.performance_summary['P/E'] ? (portfolio.performance_summary['P/E']['Portfolio']|number:'1.2-2') : '0' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">P/S</span>
                                <span class="info-value" style="padding-right: 15px;">{{ portfolio.performance_summary['P/S'] ? (portfolio.performance_summary['P/S']['Portfolio']|number:'1.2-2') : '0' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">P/B</span>
                                <span class="info-value" style="padding-right: 15px;">{{ portfolio.performance_summary['P/B'] ? (portfolio.performance_summary['P/B']['Portfolio']|number:'1.2-2') : '0' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Avg. Market Cap</span>
                                <span class="info-value">USD {{ portfolio.performance_summary['Market Value'] ? (portfolio.performance_summary['Market Value']['Portfolio']|number:'1.0-0') : '0' }}
                                    </span>M
                            </div>
                            <div class="info-line">
                                <span class="info-title">Avg. Enterprise Value</span>
                                <span class="info-value">USD {{ portfolio.performance_summary['Enterprise Value'] ? (portfolio.performance_summary['Enterprise Value']['Portfolio']|number:'1.0-0') : '0' }}
                                    </span>M
                            </div>
                            <div class="info-line">
                                <span class="info-title">Avg. EBITDA</span>
                                <span class="info-value">USD {{ portfolio.performance_summary['EBITDA'] ? (portfolio.performance_summary['EBITDA']['Portfolio']|number:'1.0-0') : '0' }}
                                   </span>M
                            </div>
                            <div class="info-line">
                            </div>
                        </div>
                    </div>
                </div>


            </mat-card>
        </div>
    </div>
<!--     </mat-card>
     <mat-card> -->
    <div class="col-md-12 flex">
        <div class="col-md-6">
            <mat-card>

                <div class="card h-100" style="height: 380px !important;">
                    <div class="card-header">Return <app-info infoIndex="7"></app-info></div>
                    <div class="card-body">
                        <div *ngIf="portfolioReturn" class="info-section">
                            <div class="info-line">
                                <span class="info-title">Return:</span>
                                <span class="info-title" style="text-align: right;">Portfolio</span>
                                <span *ngIf="portfolioReturn" class="info-title"
                                      style="text-align:right">Benchmark</span>
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">1 Month</span>
                                            <span class="info-value">{{portfolioReturn.mnt|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value">{{benchmarkReturn.mnt? (benchmarkReturn.mnt|number:'1.0-2' ): '-'}}</span>%
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">YTD</span>
                                            <span class="info-value">{{portfolioReturn.ytd|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value">{{benchmarkReturn.ytd ? (benchmarkReturn.ytd|number:'1.0-2'):  '-'}}</span>%
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">Total Return</span>
                                            <span class="info-value">{{portfolioReturn.totalReturn|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value">{{benchmarkReturn.totalReturn ? (benchmarkReturn.totalReturn|number:'1.0-2') : '-'}}</span>%
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">Annualized Return</span>
                                            <span class="info-value">{{portfolio.performance_summary['Annualized Return']['Portfolio']*100|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value">{{benchmarkReturn.cagrY1 ? (benchmarkReturn.cagrY1|number:'1.0-2') : '-'}}</span>%
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">CAGR 3Yr</span>
                                            <span class="info-value">{{portfolioReturn.cagrY3|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value">{{benchmarkReturn.cagrY3 ? (benchmarkReturn.cagrY3|number:'1.0-2') : '-'}}</span>%
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">CAGR 5Yr</span>
                                            <span class="info-value">{{portfolioReturn.cagrY5|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value">{{benchmarkReturn.cagrY5 ? (benchmarkReturn.cagrY5|number:'1.0-2') : '-'}}</span>%
                                        </div>
                                        <div class="info-line">
                                        <span class="info-title">CAGR 10Yr</span>
                                            <span class="info-value">{{portfolioReturn.cagrY10|number:'1.0-2'}}</span>%
                                            <span *ngIf="portfolioReturn" class="info-value" >{{benchmarkReturn.cagrY10 ? (benchmarkReturn.cagrY10|number:'1.0-2') : '-'}}</span>%
                                        </div>
                                       
                                </div>                       
                            </div>
                        </div>
                    </mat-card>
                </div>

        <div class="col-md-6">
            <mat-card>
                <div class="card h-100" style="height: 380px !important;">
                    <div class="card-header">Risk<app-info infoIndex="8"></app-info></div>
                    <div class="card-body">
                        <div *ngIf="(portfolioData[0])" class="info-section">
                            <div class="info-line">
                                <span class="info-title">Annualized Volatility</span>
                                <span *ngIf="(portfolioData[0])"
                                      class="info-value">{{ portfolioData[0].Volatility * 100 | number:'1.0-2' }}
                                    </span>%
                            </div>
                            <div class="info-line">
                                <span class="info-title">Sharpe Ratio</span>
                                <span class="info-value" style="padding-right: 15px;">{{ portfolio.performance_summary['Annualized Sharpe (Rf=0%)']['Portfolio']|number:'1.2-2' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Max Drawdown</span>
                                <span *ngIf="(portfolioData[0])"
                                      class="info-value">{{ portfolioData[0].maxDrawdown * 100 | number:'1.0-2' }}
                                    </span>%
                            </div>
                            <!--  <div class="info-line" style="background-color: yellow">
                                 <span class="info-title">R^2 %</span>
                                 <span class="info-value">{{portfolio.performance_summary['Annualized Sharpe (Rf=0%)']['Portfolio']|number:'1.0-2'}}</span>
                             </div> -->
                            <div class="info-line">
                                <span class="info-title">Treynor Ratio</span>
                                <span *ngIf="(portfolioData[0])"
                                      class="info-value" style="padding-right: 15px;">{{ (portfolio.performance_summary['Annualized Return']['Portfolio'] / portfolioData[0].Beta) | number:'1.2-2' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Calmar Ratio</span>
                                <span *ngIf="(portfolioData[0])"
                                      class="info-value" style="padding-right: 15px;">{{ (portfolio.performance_summary['Annualized Return']['Portfolio'] / portfolioData[0].maxDrawdown)|number:'1.0-2' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Tracking Error</span>
                                <span class="info-value">{{ portfolioData[0].TrackingError * 100 |number:'1.2-2' }}</span>%
                            </div>
                            <div *ngIf="(benchmarkReturn)" class="info-line">
                                <span class="info-title">Information Ratio</span>
                                <span class="info-value" style="padding-right: 15px;">{{ (portfolio.performance_summary['Annualized Return']['Portfolio'] - benchmarkReturn.cagrY1) / portfolioData[0].TrackingError|number:'1.2-2' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">VaR 95</span>
                                <span class="info-value">{{ portfolio.performance_summary['VaR']['Portfolio'] * 100|number:'1.2-2' }}
                                    </span>%
                            </div>
                            <div class="info-line">
                                <span class="info-title">VaR 99</span>
                                <span class="info-value">{{ portfolio.performance_summary['VaR99']['Portfolio'] * 100|number:'1.2-2' }}</span>%
                            </div>
                            <div class="info-line">
                                <span class="info-title">Alpha</span>
                                <span *ngIf="(portfolioData[0])"
                                      class="info-value">{{ portfolioData[0].Alpha * 100| number:'1.2-2' }}</span>%
                            </div>
                            <div class="info-line">
                                <span class="info-title">Beta</span>
                                <span *ngIf="(portfolioData[0])"
                                      class="info-value" style="padding-right: 15px;">{{ portfolioData[0].Beta|number:'1.2-2' }}</span>
                            </div>
                            <!-- <div class="info-line" style="background-color: yellow">
                                <span class="info-title">Skewness</span>
                                <span class="info-value">{{portfolio.performance_summary['Annualized Sharpe (Rf=0%)']['Portfolio']|number:'1.0-2'}}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </mat-card>
    </div>
    </div>
    </mat-card>

    <mat-card id="cumulative_returns">
        <mat-card-content>
            <div class="row mb-3">
                <div class="col-md-12">
                    <app-cummulative-return-chart [subHeader]="portfolio.name" [plotData]="plotData"
                                                  [enableDatawrapper]="true"
                                                  [benchmark]="benchmark" [startDate]="chartGlobalStartDate"
                                                  [endDate]="chartGlobalEndDate"></app-cummulative-return-chart>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card id="annual_performance">
        <mat-card-content>
            <div class="row mb-3">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h1>Annual Performance</h1>
                            <mat-form-field appearance="outline" class="header-select" style="float: right;">
                                <mat-label>Type</mat-label>
                                <mat-select [(ngModel)]="annualChartType" (ngModelChange)="initAnnualSharpeChart()">
                                    <mat-option [value]="'annualized_return'">Return</mat-option>
                                    <mat-option [value]="'annualized_sharpe'">Sharpe Ratio</mat-option>
                                    <mat-option [value]="'annualized_std_deviation'">Volatility</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="card-body ">
                            <app-line-chart [series]="sharpeChartData" [extraOptions]="annualChartOptions"
                                            [startDate]="chartGlobalStartDate"
                                            [endDate]="chartGlobalEndDate"></app-line-chart>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card id="weights_performance">
        <mat-card-content>
            <div class="card">
                <div class="card-body">
                    <div *ngIf="generalData.length > 0" class="row">
                        <div class="col-md-6">
                            <app-donut-chart [data]="generalData" [type]="'weight'" [title]="'Portfolio Weights'"
                                             [subHeader]="{portfolio: portfolio.name}"></app-donut-chart>
                        </div>
                        <div class="col-md-6">
                            <!--   <app-tree-map-chart *ngIf="treeMapData" [dataRaw]="treeMapData" [subHeader]="portfolio.name"
                                                  [hasETF]="portfolio.hasETF()"></app-tree-map-chart>-->
                            <app-portfolio-return-bar-chart *ngIf="treeMapData" [isPortfolioPerformance]="true"
                                                            [title]="'Portfolio Performance'"
                                                            [rawData]="treeMapData"></app-portfolio-return-bar-chart>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card id="portfolio_constituents">
        <mat-card-content>
            <div class="card">
                <div class="card-header"><h1>Portfolio Constituents</h1></div>
                <div class="card-body">
                    <!--        <app-return-charts *ngIf="drawdownData && volatilityData" [volatilityData]="volatilityData"-->
                    <!--                           [drawdownData]="drawdownData" [plotData]="plotData" [benchmark]="benchmark"-->
                    <!--                           [subHeader]="portfolio.name" [startDate]="chartGlobalStartDate"-->
                    <!--                           [endDate]="chartGlobalEndDate"-->
                    <!--                           (afterDrawdownSetExtremes)="onDrawdownDatechange($event)"></app-return-charts>-->

                    <app-cim-datatable *ngIf="generalData.length > 0"
                                       [columns]="portfolioConstituentsTableColumns"
                                       [data]="generalData"
                                       [sortProp]="'Weight'"
                                       [needPaginator]="false"
                                       sortDirection="desc"></app-cim-datatable>

                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <section id="summary-risk-contribution-chart">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <app-risk-contribution-column-chart *ngIf="riskContributionData"
                                                                [riskContributionRaw]="riskContributionData"
                                                                [subHeader]="{portfolio: portfolio.name}"></app-risk-contribution-column-chart>
                        </div>
                    </div>
                </div>
            </section>
            <section id="summary-marginal-risk-contribution-chart">
                <div class="card">
                    <div class="card-body">

                        <div class="mb-3">
                            <app-portfolio-return-bar-chart *ngIf="marginalRiskContributionData"
                                                            [title]="'Marginal Risk'"
                                                            [rawData]="marginalRiskContributionData"

                                                            [subHeader]="{portfolio: portfolio.name}"></app-portfolio-return-bar-chart>
                        </div>
                    </div>
                </div>
            </section>
        </mat-card-content>
    </mat-card>

</div>